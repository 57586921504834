import Seo from 'src/components/seo/Seo'

import Inverno from '../../components/Campanha/Inverno'

function PageInverno() {
  return (
    <>
      <Seo title="Inverno 2022 | Decathlon" />
      <Inverno />
    </>
  )
}

export default PageInverno
